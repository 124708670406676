import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';
import { environment } from '../../environments/environment.prod';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        }
        return this.user;
    }

    /**
     * Performs the auth
     * @param name name of user
     * @param password password of user
     */
     login(name: string, password: string) {
        return this.http.post<any>(environment.server+"/admin/login", {name, password })
            .pipe(map(data => {

                // login successful if there's a jwt token in the response
                if (data && data.token) {


                    this.user = data.user;
                    // store user details and jwt in cookie
                    this.cookieService.setCookie('currentUser', JSON.stringify(this.user), 1);
                }
                return data;
            }));
    }

    /**
     *
     */
     register(firstname: string, lastname: string,email: string, password: string) {
        return this.http.post<any>(environment.server+"/user/Create", { email, password ,firstname, lastname})

    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.user = null;
    }

    update(id,name,
      password
      ){

      return this.http.post<any>(environment.server+'/admin/updateProfile',{id,name,password});
      }





  uploadProfilePhoto(image,id) {
     let formData = new FormData();
     formData.append("image", image);
     formData.append("id", id);
     return this.http.post<any>(
         environment.server + "/admin/uploadAdmin",
         formData
     );
  }



}

