import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  loading = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,
    private authenticationService: AuthenticationService) {

    if (authenticationService.currentUser()) {
      this.router.navigateByUrl("/");
    }
  }

  ngOnInit() {
   

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }





  /**
   * On submit form
   */
  onSubmit(name?:any,pass?:any) {
     
  this.loading=true

  let num = 0;
  if (name.value == "") {
      name.style.border = "1px solid red";
      name.parentNode.querySelector("#error").innerHTML =
          "Name is Required!";
      num++;
  
  } else {
      name.parentNode.style.border = "none";
      name.parentNode.querySelector("#error").innerHTML = "";
  }
  if (pass.value.length < 6) {
      pass.style.border = "1px solid red";
      pass.parentNode.querySelector("#error2").innerHTML =
          "Password Is Required ";
      num++;
  } else {
      pass.parentNode.style.border = "none";
      pass.parentNode.querySelector("#error2").innerHTML = "";
  }
  if (num != 0) {
    this.loading=false
  } else {
      //   this.spinner.show();
      this.authenticationService.login(name.value, pass.value).subscribe(
          (res) => {
            console.log(res)
                this.loading=false
              if (res.apiStatus) {
                  this.router.navigate([this.returnUrl]);
                } 
                else{
                  this.error = res.message
                  document.getElementById("main_error").style.display ='block'
                }
              //  this.spinner.hide();
          },
          (err) => {
            this.loading=false
            this.error = err.message;
            document.getElementById("main_error").style.display ='block'
            
          }
      );
  }



}


}

