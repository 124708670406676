<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()"><img width=150 height=150 src='assets/images/logo@2x.png' /></a>
    </div>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="warning">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
</div>

<div class="header-container">
    <nb-actions size="small">

        <!--<nb-action class="control-item">
            <nb-search type="rotate-layout"></nb-search>
        </nb-action>-->
        <!-- <nb-action class="control-item" icon="email-outline"></nb-action>-->
        <nb-action class="control-item" icon="bell-outline"></nb-action>
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly"  [name]="user.name" [picture]="imageBase64">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>